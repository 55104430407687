class Posts {
	constructor(props) {
		this.props = props

		this.data = {
			options: {},
			page: 1,
			totalCount: 0,
			loadMoreBtn: null,
			loadMoreBtnHtml: '',
			terms: []
		}

		this.state = {
			loading: true
		}
	}
	
	init() {

		this.block = this.props.block

		this.innerBlock = this.block.querySelector('.posts-wrapper')

		this.data.options = JSON.parse(this.innerBlock.dataset.options)
		this.data.params = JSON.parse(this.innerBlock.dataset.params)

		this.initLoadMore()
		this.initFilter()

		this.loading = true

		this.request(this.params)
		.then(response => {

			this.buildGrid(response)

		})
		.finally(() => {
			this.innerBlock.classList.add('show')
			this.loading = false
		})
	}

	// setters & getters
	set loading(val) {
		this.state.loading = val
		this.listenerLoading()
	}

	get loading() {
		return this.state.loading
	}

	get params() {
		let options = this.data.options
		let params = this.data.params

		let data = {
			posts_per_page: options.desktopPostsPerPage,
			paged: this.data.page,
			terms: this.data.terms
		}

		// mobile
		if ( window.innerWidth < 768 ) {
			params.posts_per_page = options.mobilePostsPerPage
		} else if (window.innerWidth >= 768 && window.innerWidth <= 1024) {
			params.posts_per_page = options.tabletPostsPerPage
		}

		data = {
			...data,
			args: {...params}
		}

		return data;
	}

	// listeners
	listenerLoading() {

		if (!this.loadMoreBtn) return

		if (this.loading) {
			this.loadMoreBtn.innerHTML = 'Loading'
			this.loadMoreBtn.classList.add('loading')
		} else {
			this.loadMoreBtn.innerHTML = this.loadMoreBtnHtml
			this.loadMoreBtn.classList.remove('loading')
		}
	}

	// methods
	request() {
		return new Promise((resolve, reject) => {

			const formData = new FormData()
			formData.append('action', ajax.action)
			formData.append('nonce', ajax.nonce)
			formData.append('params', JSON.stringify(this.params))
			const data = new URLSearchParams(formData)

			fetch(ajax.ajaxUrl, {
				method: 'POST',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
					'Cache-Control': 'no-cache',
				},
				body: data
			})
			.then(response => response.json())
			.then(data => {
				resolve(data)
			})
			.catch(error => {
				reject(error)
			})
		})
	}

	reset() {
		this.block = this.props.block
		let innerBlock = this.block.querySelector('.posts-wrapper')
		let loadMoreWrapper = this.block.querySelector('.btn-load-more-wrapper')

		if (loadMoreWrapper)
			loadMoreWrapper.classList.remove('disabled')

		innerBlock.innerHTML = ''
		this.data.page = 1
		this.data.totalCount = 0
	}

	buildGrid(response) {

		let contentString = response.html

		let content = document.createRange().createContextualFragment(contentString)

		if (this.data.options.transitionContainer) {
			const gridContainer = document.createElement('div')
			gridContainer.classList.add('post-grid', `grid-${response.data.count}`)
			gridContainer.appendChild(content)
			this.innerBlock.appendChild(gridContainer)
		} else {
			this.innerBlock.appendChild(content)
		}

		this.data.page++
		this.data.totalCount = this.data.totalCount + response.data.count

		if ( this.data.totalCount === response.data.total ) {
			let loadMoreWrapper = this.innerBlock.parentElement.querySelector('.btn-load-more-wrapper')

			if (loadMoreWrapper)
				loadMoreWrapper.classList.add('disabled')
		}
	}

	initLoadMore() {
		this.loadMoreBtn = this.block.querySelector('.load-more-btn')

		if ( !this.loadMoreBtn ) return

		this.loadMoreBtnHtml = this.loadMoreBtn.innerHTML

		this.loadMoreBtn.addEventListener('click', (e) => {
			e.preventDefault()

			this.loading = true

			this.request()
			.then(response => {
				this.buildGrid(response)
			})
			.finally(() => {
				this.loading = false
			})
		})
	}

	initFilter() {
		const filters = this.block.querySelectorAll('.filter')

		if ( filters.length > 0 ) {
			filters.forEach(filter => {
				filter.addEventListener('click', (e) => {
					const li = e.target.closest('li')

					if(li.dataset.term !== 'all') {
						this.data.terms[0] = {
							taxonomy: li.dataset.taxonomy,
							terms: li.dataset.term
						}
					} else {
						this.data.terms = []
					}
					

					this.reset()
	
					this.loading = true

					this.request()
					.then(response => {
						this.buildGrid(response)
					})
					.finally(() => {
						this.loading = false
					})
				})
			})
		}
	}
}

window.addEventListener('DOMContentLoaded', () => {
	const windowWidth = window.innerWidth

	const blocks = document.querySelectorAll('.elmntl-posts')
		
	blocks.forEach(block => {
		new Posts({block}).init()
	})

	window.addEventListener('resize', _.debounce(() => {
		if ( window.innerWidth != windowWidth ) {
			blocks.forEach(block => {
				const postsBlock = new Posts({block})

				postsBlock.reset()
				postsBlock.init()
			})
		}
	}, 250))
})

if (window.acf) {
	window.acf.addAction(
		'render_block_preview/type=posts', (postBlock) => {
			const block = postBlock[0]
			new Posts({block}).init()
		}
	)
}